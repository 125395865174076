<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>ADJUNTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">
              Nombre
            </th>
            <th class="text-center">
              Descargar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in adjuntos" :key="item.id">
            <td class="text-left">{{ item.ruta }}</td>
            <td class="text-center">
              <v-btn @click="descargarArchivo(item.id,item.ruta)" density="compact" icon="mdi-download"></v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdjuntosVisitante',
  data() {
    return {};
  },
  computed: {
    adjuntos() {
      return this.$store.state.formato_visitante_seleccionado.adjuntos;
    },
  },
  methods: {
    descargarArchivo(id, nombreArchivo) {
      // Asegurar que id es una cadena
      const stringId = id.toString();

      // Construir el URL completo desde donde se descargará el archivo
      const baseURL = process.env.VUE_APP_API_URL ?? 'http://localhost';
      const url = `${baseURL}/api/adjuntosfv/${stringId}`;

      // Extraer la extensión del nombre del archivo para realizar validaciones si es necesario
      const extension = nombreArchivo.split('.').pop();

      // Validar que la extensión sea de un tipo de archivo que queremos permitir
      if (!['jpg', 'jpeg', 'pdf'].includes(extension.toLowerCase())) {
        console.error('Error: Tipo de archivo no compatible');
        return;
      }

      // Crear un elemento <a> para simular un clic para descargar
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nombreArchivo);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Limpiar removiendo el enlace después de usarlo
    }
  },
};
</script>
