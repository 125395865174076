<template>
  <v-row>
    <v-col>
      <h3>SEGUIMIENTOS</h3>
      <draggable class="list-group" :list="list1" group="people" @change="log">
        <template #item="{ element }">
          <div class="list-group-item">
            {{ element.name }}
          </div>
        </template>
      </draggable>
    </v-col>
    <v-col>
      <h3>JACKELINE VARGAS</h3>
      <draggable class="list-group" :list="list2" group="people" @change="log">
        <template #item="{ element }">
          <div class="list-group-item">
            {{ element.name }}
          </div>
        </template>
      </draggable>
    </v-col>
    <v-col>
      <h3>RONALDO YAÑEZ</h3>
      <draggable class="list-group" :list="list3" group="people" @change="log">
        <template #item="{ element }">
          <div class="list-group-item">
            {{ element.name }}
          </div>
        </template>
      </draggable>
    </v-col>
    <v-col>
      <h3>ALDAIR DURAN</h3>
      <draggable class="list-group" :list="list4" group="people" @change="log">
        <template #item="{ element }">
          <div class="list-group-item">
            {{ element.name }}
          </div>
        </template>
      </draggable>
    </v-col>
    <v-col>
      <h3>RICHARD GAMERO</h3>
      <draggable class="list-group" :list="list5" group="people" @change="log">
        <template #item="{ element }">
          <div class="list-group-item">
            {{ element.name }}
          </div>
        </template>
      </draggable>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,

  },
  data() {
    return {
      list1: [
        { name: "CRISTHIAN VARGAS TICONA", id: 1 },

      ],
      list2: [
      ],
      list3: [
      ],
      list4: [
      ],
      list5: [
      ],
    };
  },
  methods: {
    log: function (evt) {
      window.console.log(evt);
    },
  },
};
</script>
