<template>
  <v-card class="mx-4">
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>VISOR DE ADJUNTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>

      <template v-if="evidencia && evidencia.tipo_archivo == 'pdf'">
        <vue-pdf-app style="height: 100vh;" :pdf="fotos[0]"></vue-pdf-app>
      </template>
      <template v-else>
        <div class="images" v-viewer.rebuild="{ inline: true, navbar: false, button: false }">
          <img v-for="src in fotos" :key="src" :src="src">
        </div>

      </template>
      <!--   -->

    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
export default {

  name: 'VisorEvidenciasDeclaracionMedicamento',
  components: {
    VuePdfApp
  },
  data() {
    return {
      images: [
        "https://picsum.photos/200/200",
        "https://picsum.photos/300/200",
        "https://picsum.photos/250/200"
      ],
      headers: [
        { text: 'Descripcion', align: 'start', value: 'descripcion', },
        { text: 'Acciones', value: 'acciones' },
      ],
      moment,
      isLoading: true,
    }
  },
  methods: {

  },
  watch: {

  },
  computed: {
    medicamentos: {
      get() {
        return this.$store.state.declaracion_medicamento_seleccionada.medicamento
      },
      set() {

      }
    },
    fotos: {
      get() {
        return this.$store.state.fotos_evidencia_medicamento
      },
      set() {
      }
    },
    evidencia() {
      return this.$store.state.evidencia_medicamento_seleccionada
    },
  }
}
</script>
<style>
img {
  max-height: 60vh;
  height:auto;
}
</style>
