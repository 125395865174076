<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar dense color="indigo-darken-4" dark>
        <v-spacer></v-spacer>
        <h5>COMENTARIOS PACIENTE</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-textarea v-if="comentario" variant="outlined" rows="3" v-model="comentario" no-resize readonly>
        </v-textarea>
        <template v-else>
          <p>Sin data para mostrar</p>
        </template>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>
<script>
export default {
  name: 'comentariosDeclaracionMedicamento',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    comentario() {
      if (this.$store.state.declaracion_medicamento_seleccionada.comentario) return this.$store.state.declaracion_medicamento_seleccionada.comentario.comentario.toUpperCase();
      return '';
    }
  }
}
</script>