<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>FUNCIONES VITALES</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-text-field v-model="signos.peso" density="compact" hide-details type="number" label="PESO"
        suffix="Kg"></v-text-field>
      <v-text-field v-model="signos.talla" density="compact" hide-details type="number" label="TALLA"
        suffix="mts"></v-text-field>
      <v-text-field v-model="imc.valor" readonly density="compact" hide-details type="number" label="IMC">
        <template v-slot:append-inner>
          <v-icon v-bind="props" :icon="imc.icon" :color="imc.color"></v-icon>
        </template>
      </v-text-field>
      <!--      <v-chip class="mt-4" density="compact" size="small" :color="imc.color" variant="tonal">
        IMC 
      </v-chip> -->
      <v-text-field v-model="signos.presion_arterial" density="compact" hide-details
        label="PRESION ARTERIAL"></v-text-field>
      <v-text-field v-model="signos.frec_cardiaca" type="number" density="compact" hide-details
        label="FRECUENCIA CARDIACA" suffix="x’"></v-text-field>
      <v-text-field v-model="signos.frec_respiratoria" type="number" density="compact" hide-details
        label="FRECUENCIA RESPIRATORIA" suffix="x’"></v-text-field>
      <v-text-field v-model="signos.saturacion_o2" type="number" density="compact" hide-details label="SATURACION O2"
        suffix="%"></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DatosVisitante',
  data() {
    return {
      imc: {
        valor: '',
        color: '',
        icon: ''
      }
    }
  },
  computed: {
    signos() {
      const signosVitales = this.$store.state.formato_visitante_seleccionado.signosvitales;
      return signosVitales ? signosVitales : { peso: '', talla: '', presion_arterial: '', frec_cardiaca: '', frec_respiratoria: '', saturacion_o2: '' };
    }
  },
  watch: {
    'signos.peso': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getIMC();
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, peso: newValue });
        }
      },
      deep: true
    },
    'signos.talla': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getIMC();
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, talla: newValue });
        }
      },
      deep: true
    },
    'signos.presion_arterial': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, presion_arterial: newValue });
        }
      },
      deep: true
    },
    'signos.frec_cardiaca': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, frec_cardiaca: newValue });
        }
      },
      deep: true
    },
    'signos.frec_respiratoria': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, frec_respiratoria: newValue });
        }
      },
      deep: true
    },
    'signos.saturacion_o2': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_SIGNOS_VITALES', { ...this.signos, saturacion_o2: newValue });
        }
      },
      deep: true
    }
  },
  methods: {
    getIMC() {
      const peso = parseFloat(this.signos.peso);
      const talla = parseFloat(this.signos.talla);
      if (!isNaN(peso) && !isNaN(talla) && talla !== 0) {
        const imc = peso / (talla * talla);
        this.imc.valor = imc.toFixed(2);
        this.imc.color = this.getColorIMC(imc);
        this.imc.icon = this.getIconIMC(imc);
      } else {
        this.imc.valor = '';
        this.imc.color = '';
      }
    },
    getColorIMC(imc) {
      if (imc < 18.5) {
        return 'blue';
      } else if (imc >= 18.5 && imc < 24.9) {
        return 'green';
      } else if (imc >= 25 && imc < 30) {
        return 'orange';
      } else if (imc >= 30) {
        return 'red';
      } else {
        return '';
      }
    },
    getIconIMC(imc) {
      if (imc < 18.5) {
        return 'mdi-alert';
      } else if (imc >= 18.5 && imc < 24.9) {
        return 'mdi-check-circle-outline';
      } else if (imc >= 25 && imc < 30) {
        return 'mdi-alert';
      } else if (imc >= 30) {
        return 'mdi-close-circle-outline';
      } else {
        return '';
      }
    }
  }
}
</script>