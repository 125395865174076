<template>
  <v-dialog v-model="dialog" fullscreen persistent>

    <v-card>
      <v-toolbar dark color="indigo darken-4">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>CALIFICACION DEL MEDICO</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        <span class="text-h5">Calificar Declaración</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select v-model="form.idestacion" :items="estaciones" item-text="nombre_estacion"
                item-value="idestaciones" label="Punto de Atención" persistent-hint return-object single-line></v-select>
            </v-col>
            <v-col cols="12">
              <v-switch v-model="estado_atencion_seleccionada" label="Marcar como Revisado"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" dark @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn color="blue darken-1" dark @click="guardar()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {
        idestacion: null,
        estado: 0,
      },
      switch1: true,
    }
  },
  computed: {
    dialog: {
      get() { return this.$store.state.dialogCalificacionAtencionMedicamento; },
      set(val) { this.$store.commit('SET_DIALOG_CALIFICACION_ATENCION_MEDICAMENTO', val) }
    },
    estado_atencion_seleccionada: {
      get() { return this.$store.state.estado_atencion_seleccionada; },
      set(val) { this.$store.commit('SET_ESTADO_ATENCION_SELECCIONADA', val) }
    },
    estaciones() {
      return this.$store.state.estaciones;
    },
    id_atencion_seleccionada: {
      get() { return this.$store.state.id_atencion_seleccionada; },
      set(val) { this.$store.commit('SET_ID_ATENCION_SELECCIONADA', val) }
    }
  },
  methods: {
 /*    cargarEstaciones() {
      this.$store.dispatch('fetchEstaciones')
    }, */
    guardar() {
      const data = {
        estacion_id: this.form.idestacion,
        estado: this.estado_atencion_seleccionada,
        id_atencion :this.id_atencion_seleccionada
      }
      this.$store.dispatch('saveCalificacion', data)
    }
  },
  created() {
   /*  this.cargarEstaciones(); */
  }
}
</script>
