<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title>LISTADO DE FORMATOS</v-card-title>
      <v-card-text>
        <v-data-table-server :headers="headers" :items="$store.state.formato_visitantes.data" :loading="isLoading"
          v-model:items-per-page="itemsPerPage" v-model:options="options" @update:options="cargarData()"
          :items-length="$store.state.formato_visitantes.total" density="compact" class="elevation-0"
          :items-per-page-options="itemsPorPagina">
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY hh:mm A') }}
          </template>
          <template v-slot:[`item.visitante.apellidos_nombres`]="{ item }">
            {{ item.visitante.apellidos_nombres.toUpperCase() }}
          </template>
          <template v-slot:[`item.visitante.documento_identidad`]="{ item }">
            {{ item.visitante.documento_identidad }}
          </template>
          <template v-slot:[`item.visitante.empresa`]="{ item }">
            <template v-if="item.visitante.empresa !== null">
              {{ item.visitante.empresa.toUpperCase() }}
            </template>
          </template>
          <template v-slot:[`item.visitante.sitio`]="{ item }">
            <template v-if="item.visitante.sitio !== null">
              {{ item.visitante.sitio.toUpperCase() }}
            </template>
          </template>
          <template v-slot:[`item.habilitacion`]="{ item }">
            <v-chip v-if="item.habilitacion && item.habilitacion.fv_habilitacion.id == 1" color="red" size="small"
              text="NO PROCEDE"></v-chip>
            <v-chip v-if="item.habilitacion && item.habilitacion.fv_habilitacion.id == 2" color="green" size="small"
              text="CONTINUA"></v-chip>
            <v-chip v-if="!item.habilitacion" size="small" color="orange" text="EN PROCESO"></v-chip>
          </template>
          <template v-slot:[`item.calificacion`]="{ item }">
            <v-chip v-if="item.calificacion && item.calificacion.aptitud.id == 1" color="red" size="small"
              text="NO APTO"></v-chip>
            <v-chip v-if="item.calificacion && item.calificacion.aptitud.id == 2" color="green" size="small"
              text="APTO"></v-chip>
            <v-chip v-if="item.calificacion && item.calificacion.aptitud.id == 3" color="red" size="small"
              text="NO ASISTIO"></v-chip>
            <v-chip v-if="!item.calificacion" size="small" color="orange" text="EN PROCESO"></v-chip>
          </template>
          <template v-slot:[`item.programacion`]="{ item }">
            <v-chip v-if="item.programacion && item.programacion.length" color="green" size="small"
              text="PROGRAMADO"></v-chip>
          </template>
          <template v-slot:[`item.posta`]="{ item }">
            <v-chip v-if="item.programacion && item.programacion.length" color="green" size="small"
              :text="item.programacion[item.programacion.length - 1].posta.descripcion"></v-chip>
          </template>
          <template v-slot:[`item.programacion.fecha`]="{ item }">
            <v-chip v-if="item.programacion && item.programacion.length" color="green" size="small"
              :text="moment(item.programacion[item.programacion.length - 1].fecha).format('DD-MM-YYYY')"></v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn v-if="item.habilitacion && item.habilitacion.fv_habilitacion.id == 2" class="ma-2"
              @click="abrirDialogo(item)" density="compact" size="medium" variant="text"
              icon="mdi-calendar-multiselect-outline"></v-btn>
          </template>
          <template v-slot:thead>
            <tr>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaInicio" hide-details label="Fecha Inicio" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaFinal" hide-details label="Fecha Fin" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-text-field v-model="params.queryVisitante" hide-details placeholder="Buscar" type="text" class="ma-2"
                  density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryNumeroDocumento" hide-details placeholder="Buscar" type="number"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryEmpresa" hide-details placeholder="Buscar" type="text" class="ma-2"
                  density="compact"></v-text-field>
              </td>
              <td>
              </td>
              <td>
              </td>
              <td>
                <v-select v-model="params.queryHabilitacion" item-value="id" item-title="descripcion" label="Buscar"
                  multiple class="ma-2" hide-details :items="itemsHabilitacion" density="compact"></v-select>
              </td>
              <td>
                <v-select v-model="params.queryCalificacion" item-value="id" item-title="descripcion" label="Buscar"
                  multiple class="ma-2" hide-details :items="itemsCalificacion" density="compact"></v-select>
              </td>
              <td>
                <v-select v-model="params.queryProgramacion" label="Buscar" class="ma-2" item-value="id" clearable
                  item-title="descripcion" hide-details :items="itemsProgramacion" density="compact"></v-select>
              </td>
              <td>
                <v-select v-model="params.queryPosta" multiple label="Buscar" class="ma-2" item-value="id"
                  item-title="descripcion" hide-details :items="$store.state.postas" density="compact"></v-select>
              </td>
              <td>
                <v-date-input clearable v-model="params.queryFechaProgramacion" multiple prepend-icon="" hide-details
                  class="ma-2" label="Buscar" density="compact"></v-date-input>
              </td>
            </tr>
          </template>
        </v-data-table-server>
        <DialogProgramarVisita :isVisible="dialogVisible" :Id="selectedItem?.id"
          :Name="selectedItem.visitante?.apellidos_nombres"
          :Empresa="selectedItem.visitante?.empresa_visitante.descripcion" :programaciones="programaciones"
          @update:isVisible="dialogVisible = $event" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import DialogProgramarVisita from '@/components/DialogProgramarVisita.vue'

export default {
  name: 'ListadoSeguimientosView',
  data() {
    return {
      itemsPorPagina: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ],
      moment,
      habilitacion: null,
      posta: null,
      isLoading: false,
      params: {},
      options: {},
      totalItems: 0,
      itemsPerPage: 10,
      dialogVisible: false,
      selectedItem: {},
      itemsHabilitacion: [
        { id: 1, descripcion: 'NO PROCEDE' },
        { id: 2, descripcion: 'CONTINUA' },
        { id: 3, descripcion: 'EN PROCESO' },
      ],
      itemsCalificacion: [
        { id: 1, descripcion: 'NO APTO' },
        { id: 2, descripcion: 'APTO' },
        { id: 3, descripcion: 'NO ASISTIO' },
        { id: 4, descripcion: 'EN PROCESO' }
      ],
      itemsProgramacion: [
        { id: 1, descripcion: 'NO PROGRAMADO' },
        { id: 2, descripcion: 'PROGRAMADO' },
      ],
    }
  },

  components: {
    DialogProgramarVisita
  },
  methods: {
    async generarExcel() {
      this.params = {
        ...this.params,
        ...this.options
      }
      await this.$store.dispatch('reporteExcel', this.params)
    },
    async cargarData() {
      this.params = {
        ...this.params,
        ...this.options
      }
      try {
        await this.$store.dispatch('fetchFormatoVisita', this.params)
      } catch (e) {
        this.isLoading = false
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    },
    abrirDialogo(item) {
      this.selectedItem = item;
      this.dialogVisible = true;
      this.programaciones = item.programacion; // Asigna las programaciones del ítem seleccionado
      this.empresaVisitanteDescripcion = item.empresa_visitante?.descripcion || ''; // Asigna la descripción de la empresa visitante
    },
    verDetalle(item) {
      this.$router.push({ path: `/detalleFormato/${item.id}` })
    },
    limpiar() {
      this.params.queryPaciente = null
      this.params.fechaSeguimiento = null
      this.cargarData()
    },
    verFotos(fotos) {
      this.images = [];
      fotos.forEach(foto => {
        this.images.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + foto.ruta);
      });
      this.$viewer.show();
    },
    verMedicamentos(data) {
      this.medicamentos = data
      this.$store.commit('SET_DIALOG_DETALLE_MEDICAMENTOS', true)
    },
    calificar(item) {
      this.$store.commit('SET_DIALOG_CALIFICACION_ATENCION_MEDICAMENTO', true)
      this.$store.commit('SET_ID_ATENCION_SELECCIONADA', item.id)
    },
    fetchPostas() {
      this.$store.dispatch('fetchPostas')
    },

  },
  computed: {
    headers() {
      return [
        { title: 'Fecha', align: 'center', sortable: false, key: 'created_at' },
        { title: 'Visitante', align: 'center', sortable: false, key: 'visitante.apellidos_nombres' },
        { title: 'Numero de Documento', align: 'center', sortable: false, key: 'visitante.documento_identidad' },
        { title: 'Empresa', align: 'center', sortable: false, key: 'visitante.empresa_visitante.descripcion' },
        { title: 'Detalle Empresa', align: 'center', sortable: false, key: 'visitante.empresa' },
        { title: 'Sitio', align: 'center', sortable: false, key: 'visitante.sitio' },
        { title: 'Habilitacion', align: 'center', sortable: false, key: 'habilitacion' },
        { title: 'Aptitud', align: 'center', sortable: false, key: 'calificacion' },
        { title: 'Programación', align: 'center', sortable: false, key: 'programacion' },
        { title: 'Posta', align: 'center', sortable: false, key: 'posta' },
        { title: 'Fecha', align: 'center', sortable: false, key: 'programacion.fecha' },
        { title: 'Programar Visita', align: 'center', sortable: false, key: 'acciones' },
      ]
    },
    postas() {
      return this.$store.state.postas
    }
  },
  created() {
    this.cargarData();
    this.fetchPostas();
  },
  watch: {
    'params.queryFechaInicio'() {
      this.cargarData()
    },
    'params.queryFechaFinal'() {
      this.cargarData()
    },
    'params.queryNumeroDocumento'() {
      this.cargarData()
    },
    'params.queryVisitante'() {
      this.cargarData()
    },
    'params.queryEmpresa'() {
      this.cargarData()
    },
    'params.queryHabilitacion'() {
      this.cargarData()
    },
    'params.queryCalificacion'() {
      this.cargarData()
    },
    'params.queryProgramacion'() {
      this.cargarData()
    },
    'params.queryPosta'() {
      this.cargarData()
    },
    'params.queryFechaProgramacion'() {
      this.cargarData()
    },
  },
}
</script>
