<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
      <v-toolbar dark color="indigo-darken-4">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>FORMATO DE VISITA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-toolbar>
      <VuePdfEmbed ref="pdfRef" :source="source1" />
  </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
export default {
  name: "DialogPdfFichaInvestigacion",
  components: {
    VuePdfEmbed,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      src: null,
      source1: {},

    }
  },
  computed: {
    dialog() {
      return this.$store.state.dialog_imprimir_formato_visita
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$store.commit('SET_DIALOG_IMPRIMIR_FORMATO_VISITA',false)
    },
    onShowPdf(id) {
      this.$store.dispatch('showPdfFormatoVisita', id)
        .then(url => {
          this.source1 = url
        })
        .catch(() => { })
    },
    print() {
      this.$refs.pdfRef.print(100, 'consentimiento.pdf', true)
    }
  },
}
</script>