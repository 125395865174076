<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>CONDICION MEDICA</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-switch v-for="pregunta in cuestionario" :key="pregunta.id" density="compact" :label="pregunta.descripcion"
        color="success" readonly hide-details :modelValue="isSwitchActive(pregunta.id)"></v-switch>
        <v-text-field v-if="otros" v-model="otros.respuesta" hide-details density="compact"></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'ListadoMedicamentos',
  data() {
    return {
      moment
    }
  },
  computed: {
    cuestionario_paciente() {
      return this.$store.state.formato_visitante_seleccionado.preguntas
    },
    cuestionario() {
      return this.$store.state.pregunta_formato
    },
    otros() {
      return this.$store.state.formato_visitante_seleccionado.otrospregunta
    }
  },
  methods: {
    isSwitchActive(preguntaId) {
      return Boolean(this.cuestionario_paciente.some(pregunta => pregunta.pivot.pregunta_formato_id == preguntaId));
    }
  },
  created() {
    this.$store.dispatch('FetchCuestionario')
  }
}
</script>
