<template>
  <v-card outlined>
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img v-for="(src, i) in images" :src="src" :key="i" class="image" />
    </viewer>
    <v-sheet rounded="lg">
      <v-toolbar color="indigo-darken-4">
        <v-spacer></v-spacer>
        <h5>EVIDENCIAS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Fecha de creación</th>
                <th class="text-left">Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in evidencias" :key="item.id">
                <td>{{ moment(item.created_at).format('DD-MM-YYYY') }}</td>
                <td>
                  <v-btn class="ma-2" @click="verFotos(item)" density="compact" variant="text" icon="mdi-eye"></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        <!-- <template v-else>
          <p v-text="$t('messages.no_data_found')"></p>
        </template> -->
      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'ListadoEvidencias',
  data() {
    return {
      moment,
      images: []
    }
  },
  computed: {
    evidencias: {
      get() {
        return this.$store.state.evidencias_seguimiento_seleccionado
      },
      set(val) {
        this.$store.commit('SET_EVIDENCIAS_SEGUIMIENTO_SELECCIONADO', val)
      }
    },
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    verFotos(fotos) {
      console.log(fotos)
      this.images = [];
      this.images.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + fotos.ruta);

      this.$viewer.show();
    }
  }
}
</script>
<style>
.image {
  display: none;
}
</style>