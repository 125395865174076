<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>HISTORIAL DE EMAS / EMPOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-timeline side="end" align="start">
        <v-timeline-item v-for="(s, i) in examenes_medicos" :dot-color="$store.getters.aptitudesMediwebById(s.aptitud).color"
          :key="i" size="small">
          <div class="d-flex">
            <strong class="me-4" color="red"> {{ moment(s.fecha).format('DD-MM-YYYY') }}</strong>
            <div>
              <strong>{{ $store.getters.aptitudesMediwebById(s.aptitud).text }}</strong>
              <div class="text-caption">
                {{ s.descripcion }}
              </div>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'SeguimientosTimeline',
  data() {
    return {
      moment,
    }
  },
  methods: {
    textTimeline(decision_medica) {
      if (decision_medica === 1) {
        return 'TERMINADO'
      }
      return 'PENDIENTE'
    }
  },
  computed: {
    examenes_medicos() {
      return this.$store.state.examenes_medicos_trabajador
    }
  },
  created() {
  }
}

</script>