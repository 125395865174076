<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>INFORMACION ADICIONAL</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-text-field v-if="medicacion" v-model="medicacion.medicacion" readonly density="compact" hide-details
        label="MEDICACIÓN"></v-text-field>
      <v-text-field v-if="alergias" v-model="alergias" readonly density="compact" hide-details
        label="ALERGIAS"></v-text-field>
      <p v-else class="text-center mt-2">NO HAY INFORMACIÓN PARA MOSTRAR</p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'MedicacionActual',
  data() {
    return {
    }
  },
  computed: {
    medicacion() {
      return this.$store.state.formato_visitante_seleccionado.medicacion
    },
    alergias() {
      if(this.$store.state.formato_visitante_seleccionado.visitante.alergias) return this.$store.state.formato_visitante_seleccionado.visitante.alergias.descripcion;
      return null;
    }
  },
  methods: {
  },
  created() {
  }
}
</script>
