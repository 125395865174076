<template>
  <v-card outlined>
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img v-for="(src, i) in images" :src="src" :key="i" class="image" />
    </viewer>
    <v-sheet rounded="lg">
      <v-toolbar color="indigo-darken-4">
        <v-spacer></v-spacer>
        <h5>LISTADO DE DESCANSOS MEDICOS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Fecha Creacion</th>
              <th class="text-left">Fecha Inicio DM</th>
              <th class="text-left">Fecha Fin DM</th>
              <th class="text-left">Dias</th>
              <th class="text-left">CIE10</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in descansos" :key="item.id">
              <td>{{ moment(item.created_at).format('DD-MM-YYYY') }}</td>
              <td>{{ item.fecha_inicio }}</td>
              <td>{{ item.fecha_fin }}</td>
              <td>{{ diferenciaDias(item.fecha_inicio, item.fecha_fin) }}</td>
              <td>{{ item.enfermedad ? item.enfermedad.descripcion : '' }}</td>
              <td>
                <v-btn class="ma-2" @click="onOpenDialogDescansoMedico(i)" density="compact" variant="text"
                  icon="mdi-pencil"></v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
        <!--   <template v-else>
          <p>No se encontró información</p>
        </template> -->
      </v-card-text>
    </v-sheet>
    <!--  <DialogAtenderSolicitud @close-dialog="closeDialog" v-show="dialog" :show-dialog="dialog" :data="descansoCopy" /> -->
  </v-card>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import moment from "moment";
/* import DialogAtenderSolicitud from "@/components/DialogGuardarDescansoMedico.vue"; */
export default {
  name: 'ListadoDescansosMedicos',
  components: { /* DialogAtenderSolicitud */ Viewer },
  data() {
    return {
      moment,
      dialog: false,
      images: []
    }
  },
  computed: {
    descansos: {
      get() {
        return this.$store.state.descansos_seguimiento_seleccionado
      },
      set(val) {
        this.$store.commit('SET_DESCANSOS_SEGUIMIENTO_SELECCIONADO', val)
      }
    },
  },
  methods: {
    diferenciaDias(fecha_inicio, fecha_fin) {
      if (!fecha_inicio || !fecha_fin) return ''
      let fechaIniFormat = moment(fecha_inicio, 'YYYY-MM-DD')
      let fechaFinFormat = moment(fecha_fin, 'YYYY-MM-DD')
      return fechaFinFormat.diff(fechaIniFormat, 'days')
    },
    onOpenDialogDescansoMedico(idx) {
      this.descansoCopy = {
        ...this.descansos[idx],
        idx
      }
      this.dialog = true
    },
    closeDialog(data) {
      this.dialog = false
      if (data) {
        this.descansos[data.idx] = { ...data }
      }
      this.descansos = [...this.descansos]
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    verFotos(fotos) {
      console.log(fotos)
      this.images = [];
      this.images.push(process.env.VUE_APP_API_URL + '/api/showdm/' + fotos.ruta);

      this.$viewer.show();
    }
  }
}

</script>
