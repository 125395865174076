<template>
  <v-card outlined class="mt-0">
    <v-card-title>LISTADO DE SEGUIMIENTOS</v-card-title>
    <v-card-text>
      <v-row>
        <!-- <v-col cols="3">
          <v-text-field v-model="params.queryPaciente"
            :label="`${$t('label.full_name')} / ${$t('label.dni')} / ${$t('label.smcv_register')}`"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="params.fechaSeguimiento" :label="$t('label.tracing_date')"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="params.fechaSeguimiento" @input="menu = false" :locale="$i18n.locale"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-btn color="primary" @click="cargarData" v-text="$t('label.search')" class="mr-2"></v-btn>
          <v-btn @click="limpiar" v-text="$t('label.clean')"></v-btn>
        </v-col> -->
        <v-col cols="3">
          <v-btn @click="limpiar">LIMPIAR</v-btn>
          <v-btn color="green" class="ma-2 white--text" @click="generarExcel()">
            Reporte
            <v-icon right dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table-server v-model:items-per-page="per_page" :headers="headers" :items-length="totalItems"
        :items="seguimientos" :loading="loading" item-value="name" v-model:options="options"
        @update:options="cargarData()" :items-per-page-options="itemsPorPagina">
        <template v-slot:thead>
          <tr>
            <td>
             
            </td>
            <v-text-field v-model="params.queryAnexo" hide-details placeholder="Buscar" type="number" class="ma-2"
              density="compact"></v-text-field>
            <td>
              <v-text-field v-model="params.queryAnexo" hide-details placeholder="Buscar" type="number" class="ma-2"
                density="compact"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="params.queryAnexo" hide-details placeholder="Buscar" type="number" class="ma-2"
                density="compact"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="params.queryNumeroTelefonico" hide-details placeholder="Buscar" type="text"
                class="ma-2" density="compact"></v-text-field>
            </td>
            <td>
             
            </td>
            <td>
             
            </td>
            <td>
              <v-text-field v-model="params.queryDuracion" hide-details placeholder="Buscar" type="number" class="ma-2"
                density="compact"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="params.queryDuracion" hide-details placeholder="Buscar" type="number" class="ma-2"
                density="compact"></v-text-field>
            </td>
            <td>
              
            </td>
          </tr>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.fecha_seguimiento ? moment(item.fecha_seguimiento).format('DD-MM-YYYY') : '' }}
        </template>
        <template v-slot:[`item.fecha_inicio`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.fecha_inicio :
            '' }}
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.fecha_fin : ''
          }}
        </template>
        <template v-slot:[`item.cie10`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.cie10 : '' }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip v-if="$store.getters.estadoSeguimientoDescansoById(item.estado)" size="small"
            :color="$store.getters.estadoSeguimientoDescansoById(item.estado).color"
            :text="$store.getters.estadoSeguimientoDescansoById(item.estado).text"></v-chip>
        </template>
        <template v-slot:[`item.detalle`]="{ item }">
          <v-btn v-if="cierre" class="" @click="cambiarEstado(item)" color="green" density="compact" variant="text" icon="mdi-lock"></v-btn>
          <v-btn v-if="!cierre" class="" @click="cambiarEstado(item)" color="orange" density="compact" variant="text" icon="mdi-lock-open-variant"></v-btn>
          <v-btn v-if="!cierre" class="ml-1" @click="verDetalle(item)" density="compact" variant="text" icon="mdi-pencil"></v-btn>
          <v-menu offset-y>
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table-server>
      <!--      <v-data-table :headers="headers" :items="seguimientos" :items-per-page="5" :loading="isLoading" class="elevation-0"
        v-model:options.sync="options" :server-items-length="totalItems">

        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.fecha_seguimiento ? moment(item.fecha_seguimiento).format('DD-MM-YYYY') : '' }}
        </template>
        <template v-slot:[`item.fecha_inicio`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.fecha_inicio :
            '' }}
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.fecha_fin : ''
          }}
        </template>
        <template v-slot:[`item.cie10`]="{ item }">
          {{ item.atencion && item.atencion.ultimo_descanso_medico ? item.atencion.ultimo_descanso_medico.cie10 : '' }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip v-if="$store.getters.estadoSeguimientoDescansoById(item.estado)" size="small"
            :color="$store.getters.estadoSeguimientoDescansoById(item.estado).color"
            :text="$store.getters.estadoSeguimientoDescansoById(item.estado).text"></v-chip>
        </template>
        <template v-slot:[`item.detalle`]="{ item }">
          <v-btn class="ma-2" @click="verDetalle(item)" density="compact" variant="text" icon="mdi-pencil"></v-btn>
          <v-menu offset-y>
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table> -->
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: 'ListadoSeguimientosView',
  data() {
    return {
      cierre:false,
      items: [
        { title: 'Correo Buena Salud' },
        /*  { title: 'Correo Primera Llamada' },
         { title: 'Correo Segunda Llamada' }, */
        { title: 'Correo No puede Laborar' },
        { title: 'Correo Reincorporacion Presencial' },
      ],
      moment,
      seguimientos: [],
      isLoading: false,
      params: {
        queryPaciente: null,
        fechaSeguimiento: null
      },
      options: {},
      totalItems: 0,
      menu: false,
      menu2: false,
      dates: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
    }
  },
  methods: {
    generarExcel() {
      /* window.open('/api/v1/cerro_verde/' + this.dates[0] + "/" + this.dates[1], "_blank"); */
      window.open('http://localhost:8000/api/excelSeguimiento', "_blank");
    },
    async cargarData() {
      this.isLoading = true
      this.params = {
        ...this.params,
        ...this.options
      }
      try {
        const data = await this.$store.dispatch('fetchSeguimientos', this.params)
        this.isLoading = false
        this.seguimientos = data.data
        this.totalItems = data.total
      } catch (e) {
        this.isLoading = false
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    },
    verDetalle(item) {
      this.$router.push({ path: `/seguimiento/${item.id}` })
    },
    limpiar() {
      this.params.queryPaciente = null
      this.params.fechaSeguimiento = null
      this.cargarData()
    },
    cambiarEstado(){
      console.log(this.cierre)
      this.cierre = !this.cierre
    }
  },
  computed: {
    headers() {
      return [
        {
          title: 'Fecha de Seguimiento',
          align: 'center',
          sortable: false,
          value: 'created_at',
        },
        { title: 'Medico', key: 'user.full_name', sortable: false, align: 'center' },
        { title: 'Numero Documento', key: 'atencion.paciente.numero_documento', sortable: false, align: 'center' },
        { title: 'Registro SMCV', key: 'atencion.paciente.nro_registro', sortable: false, align: 'center' },
        { title: 'Nombre Completo', key: 'atencion.paciente.full_name', sortable: false, align: 'center' },
        /*         { text: this.$t('label.dm'), value: 'protein' }, */
        { title: 'Fecha de Inicio DM', key: 'fecha_inicio', sortable: false, align: 'center' },
        { title: 'Fecha de FIn DM', key: 'fecha_fin', sortable: false, align: 'center' },
        { title: 'CIE10', key: 'cie10', sortable: false, align: 'center' },
        { title: 'Estado', key: 'estado', sortable: false, align: 'center' },
        { title: 'Acciones', key: 'detalle', sortable: false, align: 'center' },
      ]
    },
    dateStr() {
      return moment(this.dates[0]).format('DD/MM/YY') + ' ~ ' + moment(this.dates[1]).format('DD/MM/YY')
    },
  },
  created() {
    this.cargarData()
  },
  watch: {
    options() {
      this.cargarData()
    }
  }
}
</script>


