<template>
  <v-row dense>
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <InformacionVisitante class="mt-2 mx-4" />
      <FuncionesVitalesVisitante class="mt-2 mx-4" />
    </v-col>
    <v-col cols="12" lg="4" md="12" sm="12" xs="12">
      <CuestionarioVisitante class="mt-2 mx-4" />
      <MedicacionActual class="mt-2 mx-4" />
    </v-col>
    <v-col cols="12" lg="5" md="12" sm="12" xs="12">
    
      <AdjuntosVisitante class="mt-2 mx-4" />
      <Habilitacion class="mt-2 mx-4" />
      <CalificacionVisitante class="mt-2 mx-4" />
      <div class="d-flex ma-4">
        <v-spacer></v-spacer>
        <v-btn append-icon="mdi-printer" @click="abrirDialogImprimir()" color="blue" class="mx-2">IMPRIMIR
        </v-btn>
        <v-btn :loading="loading" append-icon="mdi-printer" color="success" @click="guardar()">
          GUARDAR
          <template v-slot:loader>
            <v-progress-linear indeterminate></v-progress-linear>
          </template>
        </v-btn>
      </div>
      <DialogFormatoVisita ref="dialogPdf" />
    </v-col>
  </v-row>

</template>
<script>
import InformacionVisitante from "@/components/InformacionVisitante.vue";
import CuestionarioVisitante from "@/components/CuestionarioVisitante";
import FuncionesVitalesVisitante from "@/components/FuncionesVitalesVisitante";
import CalificacionVisitante from "@/components/CalificacionVisitante";
import AdjuntosVisitante from "@/components/AdjuntosVisitante";
import DialogFormatoVisita from '@/components/DialogFormatoVisita';
import MedicacionActual from '@/components/MedicacionActual';
import Habilitacion from '@/components/Habilitacion.vue'


import moment from "moment";

export default {
  name: "DetallePaciente",
  components: {
    InformacionVisitante,
    CuestionarioVisitante,
    FuncionesVitalesVisitante,
    CalificacionVisitante,
    AdjuntosVisitante,
    DialogFormatoVisita,
    MedicacionActual,
    Habilitacion
  },
  data() {
    return {
      seguimiento: null,
      atencion: null,
      dialog: {
        descansoMedico: false,
      },
      moment,
      loading: false,
    };
  },
  methods: {
    async getData(idPaciente) {
      this.$store.commit("SET_LOADING", true);
      try {
        this.seguimiento = await this.$store.dispatch(
          "fetchFormatoVisitaID",
          idPaciente
        );
        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
        this.$store.commit("SET_LOADING", false);
      }
    },
    async guardar() {
      this.loading = true;
      await this.$store.dispatch("guardarFormatoVisita");
      this.loading = false;
    },
    abrirDialogImprimir() {
      this.$store.commit('SET_DIALOG_IMPRIMIR_FORMATO_VISITA', true)
      this.$refs.dialogPdf.onShowPdf(this.$route.params.id)
    }
  },
  computed: {},

  created() {
    this.getData(this.$route.params.id);
  },
};
</script>
