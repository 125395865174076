<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title>LISTADO DE DECLARACIÓN DE MEDICAMENTOS <v-btn size="small" variant="tonal" color="green"
          append-icon="mdi-microsoft-excel" @click="generarExcel()">Reporte</v-btn></v-card-title>
      <v-card-text>
        <v-data-table-server :headers="headers" :items="atencion_medicamentos.data" :loading="isLoading"
          v-model:options="options" @update:options="cargarData()"
          :items-length="atencion_medicamentos.total" density="compact" class="elevation-0"
          :items-per-page-options="itemsPorPagina">
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY hh:mm A') }}
          </template>
          <template v-slot:[`item.estado.descripcion`]="{ item }">
            <v-chip size="small" :color="item.estado.color" :text="item.estado.descripcion"></v-chip>
          </template>
          <template v-slot:[`item.aptitud.descripcion`]="{ item }">
            <v-chip size="small" :color="item.aptitud.color" :text="item.aptitud.descripcion"></v-chip>
          </template>
          <template v-slot:[`item.resumen`]="{ item }">
            <template v-if="item.evidencias && item.evidencias.length > 0">
              <v-tooltip :text="item.evidencias.length + ' adjuntos'" location="top">
                <template v-slot:activator="{ props }">
                  <v-badge dot color="green" class="mx-2">
                    <v-icon v-bind="props" icon="mdi-note-outline"></v-icon>
                  </v-badge>
                </template>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip text="Sin Receta" location="top">
                <template v-slot:activator="{ props }">
                  <v-badge dot color="red" class="mx-2">
                    <v-icon v-bind="props" icon="mdi-note-outline"></v-icon>
                  </v-badge>
                </template>
              </v-tooltip>
            </template>
            <template v-if="item.medicamento && item.medicamento.length > 0">
              <v-tooltip :text="item.medicamento.length + ' medicamentos'" location="top">
                <template v-slot:activator="{ props }">
                  <v-badge dot color="green" class="mx-2">
                    <v-icon v-bind="props" icon="mdi-medication-outline"></v-icon>
                  </v-badge>
                </template>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip text="No se agregaron medicamentos" location="top">
                <template v-slot:activator="{ props }">
                  <v-badge dot color="red" class="mx-2">
                    <v-icon v-bind="props" icon="mdi-medication-outline"></v-icon>
                  </v-badge>
                </template>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.detalle`]="{ item }">
            <v-btn class="ma-2" @click="verDetalle(item)" density="compact" variant="text" icon="mdi-magnify"></v-btn>
          </template>
          <template v-slot:thead>
            <tr>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaInicio" hide-details label="Fecha Inicio" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaFinal" hide-details label="Fecha Fin" type="date" class="ma-2"
                      density="compact"></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-text-field v-model="params.queryNumeroDocumento" hide-details placeholder="Buscar" type="number"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryNumeroRegistro" hide-details placeholder="Buscar" type="number"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-text-field v-model="params.queryNombrePaciente" hide-details placeholder="Buscar" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <!--                 <v-text-field v-model="params.queryEstado" hide-details placeholder="Buscar" type="number" class="ma-2"
                  density="compact"></v-text-field> -->
                <v-select v-model="params.queryEstado" :items="estados" item-title="descripcion" item-value="id" chips
                  label="Buscar" multiple hide-details class="mx-1" density="compact"></v-select>
              </td>
              <td>
                <!--                 <v-text-field v-model="params.queryAptitud" hide-details placeholder="Buscar" type="number" class="ma-2"
                  density="compact"></v-text-field> -->
                <v-select v-model="params.queryAptitud" :items="aptitudes" chips label="Buscar" item-title="descripcion"
                  item-value="id" multiple hide-details class="mx-1" density="compact"></v-select>
              </td>
              <td class="text-center">
                <!--   <v-btn size="small" variant="tonal" color="blue" append-icon="mdi-magnify">buscar</v-btn> -->
              </td>
              <td>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
    <DialogCalificacionAtencionMedicamento />
  </div>
</template>
<script>
import moment from "moment";
import DialogCalificacionAtencionMedicamento from "@/components/DialogCalificacionAtencionMedicamento.vue";
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
export default {
  name: 'ListadoSeguimientosView',
  data() {
    return {
      content: null,
      showDatePicker: false,
      itemsPorPagina: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ],
      moment,
      seguimientos: [],
      isLoading: false,
      params: {},
      options: {},
      totalItems: 0,
      menu: false,
      menu2: false,
      dates: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      images: [],
      medicamento: null
    }
  },

  components: {
    DialogCalificacionAtencionMedicamento, ...components,
    ...labsComponents,
  },
  methods: {
    async generarExcel() {
      this.params = {
        ...this.params,
        ...this.options
      }
      await this.$store.dispatch('reporteExcel', this.params)
    },
    async cargarData() {
      // this.isLoading = true
      this.params = {
        ...this.params,
        ...this.options
      }
      try {
        await this.$store.dispatch('fetchAtencionMedicamento', this.params)
      } catch (e) {
        this.isLoading = false
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    },
    verDetalle(item) {
      // console.log(item.value)
      this.$router.push({ path: `/declaracion/${item.id}` })
    },
    limpiar() {
      this.params.queryPaciente = null
      this.params.fechaSeguimiento = null
      this.cargarData()
    },
    verFotos(fotos) {
      console.log(fotos)
      this.images = [];
      fotos.forEach(foto => {
        this.images.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + foto.ruta);
      });
      this.$viewer.show();
    },
    verMedicamentos(data) {
      this.medicamentos = data
      console.log(data)
      this.$store.commit('SET_DIALOG_DETALLE_MEDICAMENTOS', true)
    },
    calificar(item) {
      this.$store.commit('SET_DIALOG_CALIFICACION_ATENCION_MEDICAMENTO', true)
      this.$store.commit('SET_ID_ATENCION_SELECCIONADA', item.id)

    }
  },
  computed: {
    headers() {
      return [
        { title: 'Fecha de Declaración', align: 'center', sortable: false, key: 'created_at', width: '25%' },
        { title: 'Numero Documento', align: 'center', key: 'paciente.numero_documento', sortable: false },
        { title: 'Nro Registro', align: 'center', key: 'paciente.nro_registro', sortable: false },
        { title: 'Paciente', align: 'center', key: 'paciente.full_name', sortable: false, width: '20%' },
        { title: 'Estado', align: 'center', key: 'estado.descripcion', sortable: false },
        { title: 'Aptitud', align: 'center', key: 'aptitud.descripcion', sortable: false },
        { title: 'Resumen', align: 'center', key: 'resumen', sortable: false, width: '10%' },
        { title: 'Examinar', align: 'center', key: 'detalle', sortable: false, }
      ]
    },
    atencion_medicamentos() {
      return this.$store.state.atencion_medicamentos;
    },
    estados() {
      return this.$store.state.estadosDeclaracionMedicamentos
    },
    aptitudes() {
      return this.$store.state.aptitudesDeclaracionMedicamentos
    },

  },
  created() {
    this.cargarData()
    this.$store.dispatch('fetchEstadosDeclaracionMedicamentos')
    this.$store.dispatch('fetchAptitudesDeclaracionMedicamentos')
  },
  watch: {
    'params.queryFechaInicio'() {
      this.cargarData()
    },
    'params.queryFechaFinal'() {
      this.cargarData()
    },
    'params.queryNumeroDocumento'() {
      this.cargarData()
    },
    'params.queryNumeroRegistro'() {
      this.cargarData()
    },
    'params.queryNombrePaciente'() {
      this.cargarData()
    },
    'params.queryEstado'() {
      this.cargarData()
    },
    'params.queryAptitud'() {
      this.cargarData()
    }
  },
}
</script>
<style>
.image {
  display: none;
}
</style>

