<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>HISTORIAL DE SEGUIMIENTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-timeline truncate-line="start" v-for="(s, i) in seguimientos" :key="i">
        <v-timeline-item :dot-color="s.decision_medica === 1 ? 'green-lighten-1' : 'orange'" size="small">
          <template v-slot:opposite>
            {{ textTimeline(s.decision_medica, i) }}
          </template>
          {{ moment(s.fecha_seguimiento).format('DD-MM-YYYY') }}
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'SeguimientosTimeline',
  data() {
    return {
      moment,
    }
  },
  methods: {
    textTimeline(decision_medica) {
      if (decision_medica === 1) {
        return 'TERMINADO'
      }
      return 'PENDIENTE'
    }
  },
  computed: {
    seguimientos: {
      get() {
        return this.$store.state.seguimientos_seguimiento_seleccionado
      },
      set() {

      }
    }
  }
}

</script>