<template>
  <div class="pa-4 text-center">
    <v-dialog :model-value="isVisible" @update:model-value="updateVisibility" max-width="700" persistent>
      <v-card prepend-icon="mdi-account" title="Programar Visita" class="pa-4">
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <div class="text-center">
                <p class="text-h5">{{ Name.toUpperCase() }}</p>
                <p class="text-caption">{{ Empresa.toUpperCase() }}</p>
                <p class="text-caption">{{ DescripcionEmpresa }}</p> <!-- Muestra la descripción de la empresa -->
              </div>
            </v-col>
          </v-row>
          <!-- Historial de Programaciones -->
          <v-row v-if="programaciones && programaciones.length">
            <v-col cols="12">
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Hora</th>
                    <th class="text-left">Posta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(programacion, index) in programaciones" :key="index">
                    <td>{{ moment(programacion.fecha).format('DD-MM-YYYY') }}</td>
                    <td>{{ programacion.hora }}</td>
                    <td>{{ programacion.posta.descripcion }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <!-- Campos para nueva programación -->
          <v-row>
            <v-select label="Posta" prepend-icon="mdi-hospital-building" :items="$store.state.postas"
              item-title="descripcion" item-value="id" v-model="form.posta_id"></v-select>
          </v-row>
          <v-row>
            <v-date-input label="Fecha" v-model="form.fecha"></v-date-input>
          </v-row>
          <v-row>
            <v-text-field v-model="form.hora" :active="menu2" :focus="menu2" label="Hora"
              prepend-icon="mdi-clock-time-four-outline" readonly>
              <v-menu v-model="menu2" :close-on-content-click="false" activator="parent" transition="scale-transition">
                <v-time-picker v-if="menu2" v-model="form.hora" format="24hr"></v-time-picker>
              </v-menu>
            </v-text-field>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Cerrar" variant="plain" @click="closeDialog">Cerrar</v-btn>
          <v-btn color="primary" text="Programar" variant="tonal" @click="programarVisita">Programar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VDateInput } from 'vuetify/labs/VDateInput';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import moment from "moment";
export default {
  props: {
    isVisible: Boolean,
    Name: String,
    Empresa: String,
    Id: Number,
    programaciones: Array,
    DescripcionEmpresa: String, // Nueva prop para la descripción de la empresa
  },
  data: () => ({
    moment,
    dialog: false,
    model: false,
    time: null,
    menu2: false,
    modal2: false,
    form: {
      posta_id: null,
      fecha: null,
      hora: null,
      formato_visita_id: null
    }
  }),
  components: {
    VDateInput,
    VTimePicker,
  },
  watch: {
    Id: {
      immediate: true,
      handler(newVal) {
        this.form.formato_visita_id = newVal;
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:isVisible', false);
    },
    programarVisita() {
      this.$store.dispatch('programarVisita', this.form);
      this.closeDialog();
    },
    updateVisibility(val) {
      this.$emit('update:isVisible', val);
    }
  },
  created(){

  }
}
</script>
