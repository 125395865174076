<template>
  <v-card variant="flat" class="mt-0">
    <v-card-title>LISTADO DE PACIENTES</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card variant="flat" class=" mt-0" max-width="400">
            <v-card-text>
              <v-card-text>
                <v-row>
                  <v-text-field v-model="paciente" density="compact" variant="solo" label="Buscar por Nombres o DNI"
                    single-line hide-details @keydown.enter="buscar()"></v-text-field>
                  <v-btn @click="buscar()" class="text-none mx-2" color="green" variant="flat">
                    BUSCAR
                  </v-btn>
                  <v-btn @click="nuevoPaciente()" class="text-none mx-2" color="blue" variant="flat">
                    NUEVO
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="paciente in pacientes" :key="paciente.id" cols="12" lg="4" md="4" sm="6" xs="12">
          <v-card class="mt-0">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-caption">
                  {{ paciente.nombres }} {{ paciente.apellido_paterno }} {{ paciente.apellido_materno }}
                </v-card-title>
                <v-card-subtitle>{{ paciente.numero_documento }}</v-card-subtitle>
                <v-card-actions>
                  <v-btn class="ms-2" icon="mdi-magnify" @click="buscarPaciente(paciente.id)"
                    variant="text"></v-btn>
                </v-card-actions>
              </div>
              <v-avatar class="ma-3" size="100" rounded="0">
                <v-img src="../assets/user.png"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: 'ListadoSeguimientosView',
  data() {
    return {
      paciente: null,
      items: [
        { title: 'Correo Buena Salud' },
        /*  { title: 'Correo Primera Llamada' },
         { title: 'Correo Segunda Llamada' }, */
        { title: 'Correo No puede Laborar' },
        { title: 'Correo Reincorporacion Presencial' },
      ],
      moment,
      seguimientos: [],
      isLoading: false,
      params: {
        queryPaciente: null,
        fechaSeguimiento: null
      },
      options: {},
      totalItems: 0,
      menu: false,
      menu2: false,
      dates: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      search: null,
    }
  },
  methods: {
    buscarPaciente(item) {
      this.$router.push({ path: `/detallePaciente/${item}` })
    },
    generarExcel() {
      /* window.open('/api/v1/cerro_verde/' + this.dates[0] + "/" + this.dates[1], "_blank"); */
      window.open('http://localhost:8000/api/excelSeguimiento', "_blank");
    },
    async cargarData() {
      this.isLoading = true
      this.params = {
        ...this.params,
        ...this.options
      }
      try {
        const data = await this.$store.dispatch('fetchPacientes', this.params)
        this.isLoading = false
        this.seguimientos = data.data
        this.totalItems = data.total
      } catch (e) {
        this.isLoading = false
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    },
    verDetalle(item) {
      this.$router.push({ path: `/seguimiento/${item.id}` })
    },
    nuevoPaciente() {
      this.$router.push({ path: '/nuevoPaciente' })
    },
    limpiar() {
      this.params.queryPaciente = null
      this.params.fechaSeguimiento = null
      this.cargarData()
    },
    buscar() {
      if (this.paciente.length > 2) {
        this.$store.dispatch('fetchPacientes', this.paciente)
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          title: 'Fecha de Seguimiento',
          align: 'center',
          sortable: false,
          value: 'created_at',
        },
        { title: 'Medico', key: 'user.full_name', sortable: false, align: 'center' },
        { title: 'Numero Documento', key: 'atencion.paciente.numero_documento', sortable: false, align: 'center' },
        { title: 'Registro SMCV', key: 'atencion.paciente.nro_registro', sortable: false, align: 'center' },
        { title: 'Nombre Completo', key: 'atencion.paciente.full_name', sortable: false, align: 'center' },
        /*         { text: this.$t('label.dm'), value: 'protein' }, */
        { title: 'Fecha de Inicio DM', key: 'fecha_inicio', sortable: false, align: 'center' },
        { title: 'Fecha de FIn DM', key: 'fecha_fin', sortable: false, align: 'center' },
        { title: 'CIE10', key: 'cie10', sortable: false, align: 'center' },
        { title: 'Estado', key: 'estado', sortable: false, align: 'center' },
        { title: 'Acciones', key: 'detalle', sortable: false, align: 'center' },
      ]
    },
    dateStr() {
      return moment(this.dates[0]).format('DD/MM/YY') + ' ~ ' + moment(this.dates[1]).format('DD/MM/YY')
    },
    pacientes: {
      get() { return this.$store.state.pacientes; },
      set(value) { this.$store.commit('SET_PACIENTES', value); },
    },
  },
  created() {
    this.cargarData()
  },
}
</script>


