<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar color="indigo-darken-4">
        <v-spacer></v-spacer>
        <h5>ANAMNESIS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <p>DIAGNOSTICOS</p>
        <div class="d-flex">
          <v-autocomplete v-model="enfermedad" :items="arrayEnfermedades" class="flex-full-width" v-model:search="search"
            density="compact" item-props placeholder="CIE10" item-title="descripcion" item-value="cie10" hide-no-data
            auto-select-first prepend-inner-icon="mdi-magnify">>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :title="item.props.cie10" :subtitle="item.props.descripcion"></v-list-item>
            </template>
          </v-autocomplete>
          <v-radio-group class="mt-auto" v-model="tipo_diagnostico" row>
            <v-radio label="PRIMARIO" :value="1"></v-radio>
            <v-radio label="SECUNDARIO" :value="0"></v-radio>
          </v-radio-group>
          <v-btn class="my-auto" color="green" @click="addAnammesis()">
            <v-icon>{{ editar ?
              'mdi-content-save' : 'mdi-plus' }}
            </v-icon>
          </v-btn>
        </div>
        <v-list v-if="anammesis && anammesis.length > 0" border>
          <v-list-subheader>Listado de Diagnosticos</v-list-subheader>
          <v-list-item  v-for="file in anammesis" :key="file.title" :title="file.enfermedad.cie10"
            :subtitle="file.enfermedad.descripcion">
            <template v-slot:append>
              <v-btn icon="mdi-pencil" @click="onEditarAnammesis(idx)" variant="text"></v-btn>
              <v-btn icon="mdi-delete" @click="onEliminarAnammesis(idx)" variant="text"></v-btn>
            </template>
          </v-list-item>
        </v-list>
        <!--    <div class="my-2" v-else v-text="$t('messages.no_diagnostic_provided')"></div> -->
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
          min-width="auto">
          <!-- <v-text-field v-model="fechaIni" :label="$t('label.symptoms_start_date').toUpperCase()"
            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :error-messages="errors"></v-text-field> -->


          <v-date-picker v-model="fechaIni" @input="menu = false" :locale="$i18n.locale"></v-date-picker>
        </v-menu>

      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'AnammesisComponent',
  data() {
    return {
      enfermedad: null,
      search: null,
      tipo_diagnostico: null,
      menu: false,
      fechaIni: '2023-10-16',
      editar: false,
      currentData: null,
    }
  },
  methods: {

    addAnammesis() {
      /*  if (this.editar) {
         this.anammesis[this.currentData.idx] = {
           id: this.currentData.id,
           cie10: this.enfermedad && this.enfermedad.cie10 ? this.enfermedad.cie10 : this.currentData.cie10,
           descripcion: this.enfermedad && this.enfermedad.cie10 ? this.enfermedad.descripcion : this.currentData.descripcion,
           principal: this.tipo_diagnostico
         }
       } else { */
      /* this.anammesis.push({
        id: null,
        cie10: this.enfermedad.cie10,
        descripcion: this.enfermedad.descripcion,
        principal: this.tipo_diagnostico
      }) */
      /*   } */
      this.$store.dispatch('saveAnammesis',this.enfermedad)
    },
    onEditarAnammesis(idx) {
      this.editar = true
      this.currentData = {
        ...this.anammesis[idx],
        idx
      }
      this.tipo_diagnostico = this.currentData.principal
      this.search = this.currentData.descripcion
      this.enfermedad = this.currentData.cie10
    },
    onEliminarAnammesis(idx) {
      this.anammesis.splice(idx, 1)
      this.anammesis = [...this.anammesis]
    },
    async isValid() {
      return await this.$refs.anamnesisForm.validate()
    }
  },
  computed: {
    diagnosticoValid() { return this.enfermedad && this.tipo_diagnostico != null },

    arrayEnfermedades: {
      get() {
        return this.$store.state.enfermedad_busqueda
      },
      set(val) {
        this.$store.commit('SET_ENFERMEDAD_BUSQUEDA', val)
      }
    },
    anammesis: {
      get() {
        return this.$store.state.anamnesis_seguimiento_seleccionado
      },
      set(val) {
        this.$store.commit('SET_ANAMNESIS_SEGUIMIENTO_SELECCIONADO', val)
      }
    }
  },
  created() {

  },
  watch: {
    search(val) {
      if (val.length > 2) {
        const data = {
          valor: val
        };

        this.$store.dispatch('searchEnfermedad', data)
      }
    },
  }
}

</script>