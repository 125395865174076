<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar color="indigo-darken-4">
        <v-spacer></v-spacer>
        <h5>LISTADO DE SEGUIMIENTOS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Fecha</th>
                <th class="text-left">Motivo</th>
                <th class="text-left">Fecha inicio de sintomas</th>
                <th class="text-left">Decision Médica</th>
                <th class="text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in seguimientos" :key="item.id">
                <td>{{ moment(item.fecha_seguimiento).format('DD-MM-YYYY') }}</td>
                <td>{{ item.motivo_seguimiento }}</td>
                <td>{{ item.fecha_inicio_sintomas ? moment(item.fecha_inicio_sintomas).format('DD-MM-YYYY') : '' }}</td>
                <td>{{ item.decision_medica }}</td>
                <td>
                  <v-btn class="ma-2" @click="onEditarSeguimiento(item.id)" density="compact" variant="text"
                    icon="mdi-pencil"></v-btn>
                  <v-btn class="ma-2" @click="onEliminarSeguimiento(item.id)" density="compact" variant="text"
                    icon="mdi-delete"></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        <!--  <template v-else>
          No se encontro información
        </template> -->
      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: 'ListadoSeguimientos',
  data() {
    return {
      moment,
      form: {
        comunicacion_con: null,
        info_sumis: null,
        motivo: null,
        motivo_otro_detalle: null
      },
      motivos: [
        { id: 1, text: 'Otros' }
      ]
    }
  },
  methods: {
    onEditarSeguimiento(idAtencion) {
      this.$emit('select-seguimiento', idAtencion)
    },
    onEliminarSeguimiento(idAtencion) {
      console.log(idAtencion)
    }
  },
  computed: {
    seguimientos: {
      get() {
        return this.$store.state.seguimientos_seguimiento_seleccionado
      },
      set(val) {
        this.$store.commit('SET_SEGUIMIENTOS_SEGUIMIENTO_SELECCIONADO', val)
      }
    },
  }
}

</script>