<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>DATOS DEL TRABAJADOR</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form v-model="form" @submit.prevent="nuevoPaciente()">
      <v-card-text v-if="paciente">
        <v-text-field v-model="paciente.nombres" density="compact" label="NOMBRES"
          :rules="[v => !!v || 'Nombres es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.apellido_paterno" density="compact" label="APELLIDO PATERNO"
          :rules="[v => !!v || 'Apellido paterno es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.apellido_materno" density="compact" label="APELLIDO MATERNO"
          :rules="[v => !!v || 'Apellido materno es requerido']" required></v-text-field>
        <div class="d-flex">
          <v-text-field v-model="paciente.numero_documento" density="compact" class="mr-2" label="DNI"
            :rules="[v => !!v || 'DNI es requerido']" required></v-text-field>
          <v-text-field v-model="paciente.nro_registro" density="compact" label="REGISTRO"
           ></v-text-field>
        </div>
        <v-text-field v-model="paciente.celular" density="compact" label="CELULAR"
          :rules="[v => !!v || 'Celular es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.correo" density="compact" label="CORREO"
          :rules="[v => !!v || 'Correo es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.puesto" density="compact" label="PUESTO"
          :rules="[v => !!v || 'Puesto es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.ubigeo_nacimiento" density="compact" label="UBIGEO DE NACIMIENTO"
          :rules="[v => !!v || 'Ubigeo de nacimiento es requerido']" required></v-text-field>
        <v-text-field v-model="paciente.fecha_nacimiento" density="compact" label="FECHA DE NACIMIENTO"
          :rules="[v => !!v || 'Fecha de nacimiento es requerida']" required></v-text-field>
        <div class="d-flex">
          <v-text-field v-model="paciente.edad" density="compact" readonly dense label="EDAD"
            :rules="[v => !!v || 'Edad es requerida']" required></v-text-field>
          <v-text-field v-model="paciente.sexo" density="compact" readonly dense label="SEXO"
            :rules="[v => !!v || 'Sexo es requerido']" required></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" variant="flat" type="submit">Enviar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'DatosTrabajador',
  computed: {
    apellidos() {
      return this.$store.state.trabajador_seleccionado.apellido_paterno + ' ' + this.$store.state.trabajador_seleccionado.apellido_materno;
    },
    paciente: {
      get() {
        return this.$store.state.trabajador_seleccionado;
      },
      set(newValue) {
        this.$store.commit('setTrabajadorSeleccionado', newValue);
      }
    }
  },
  methods: {
    nuevoPaciente() {
      if (!this.form) return;
      console.log('holaa')
      this.$store.dispatch('savePaciente', this.$store.state.trabajador_seleccionado);
      this.$router.push({ path: '/pacientes' });
    }
  }
};
</script>
