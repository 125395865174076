import { createRouter, createWebHashHistory } from "vue-router";
import AppContainer from "@/AppContainer.vue";
import store from "../store";
import ListadoAtencionMedicamentosView from "@/views/ListadoAtencionMedicamentosView";
import ListadoSeguimientosView from "@/views/ListadoSeguimientosView";
import ListadoSeguimientosDistribucionView from "@/views/ListadoSeguimientosDistribucionView";
import DetalleSeguimiento from "@/views/DetalleSeguimiento";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import DetalleDeclaracion from "@/views/DetalleDeclaracion.vue";
import DetallePaciente from "@/views/DetallePaciente.vue";
import NuevoPaciente from "@/views/NuevoPaciente.vue";
import ListadoPacientesView from "@/views/ListadoPacientesView";
import FormatosView from "@/views/FormatosView";
import FormatosSmcvView from "@/views/FormatosSmcvView";
import DetalleFormato from "@/views/DetalleFormato";

const routes = [
  {
    path: "/login",
    component: LoginView,
    name: "login",
  },
  {
    path: "/register",
    component: RegisterView,
    name: "register",
  },
  {
    path: "/",
    component: AppContainer,
    meta: { requiresAuth: true },
  },
  {
    path: "/medicamentos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoAtencionMedicamentosView,
        name: "medicamentos",
        meta: { requiresAuth: true },
      },
      {
        path: "/declaracion/:id",
        component: DetalleDeclaracion,
        name: "declaracion",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/seguimientos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoSeguimientosView,
        name: "seguimientos",
        meta: { requiresAuth: true },
      },
      {
        path: "/seguimiento/:id",
        component: DetalleSeguimiento,
        name: "seguimiento",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/distribucion",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoSeguimientosDistribucionView,
        name: "distribucion",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/pacientes",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoPacientesView,
        name: "home",
        meta: { requiresAuth: true },
      },
      {
        path: "/detallePaciente/:id",
        component: DetallePaciente,
        name: "detallePaciente",
        meta: { requiresAuth: true },
      },
      {
        path: "/nuevoPaciente/",
        component: NuevoPaciente,
        name: "nuevoPaciente",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/formatos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosView,
        name: "listaFormatos",
        meta: { requiresAuth: true },
      },
      {
        path: "/detalleFormato/:id",
        component: DetalleFormato,
        name: "detalleFormato",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/formatosmcv",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosSmcvView,
        name: "formatosmcv",
        meta: { requiresAuth: true },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
