<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>COMORBILIDADES</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-list dense v-if="comorbilidades.length > 0">
        <v-list-item v-for="(c, i) in comorbilidades" :key="i">
          <v-chip color="orange" outlined>{{ c }}</v-chip>
        </v-list-item>
      </v-list>
      <template v-else>
        <p>No hay data para mostrar</p>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ComorbilidadesComponent',
  data() {
    return {
      comorbilidades: []
    }
  },
  computed: {

  }
}

</script>