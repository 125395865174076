<template>
  <v-row dense>
    <v-col cols="12" lg="3" md="3" sm="3" xs="12">
      <DatosTrabajador class="mt-2 mx-4" />
    <!--   <ContactosEmergencia class="mt-2 mx-4" /> -->
      <Comorbilidades class="mt-4 mx-4" />
      <SeguimientosTimeline class="mt-4 mx-4" />
      <ExamenesOcupacionalesTimeline class="mt-4 mx-4" />
    </v-col>
    <v-col cols="12" lg="9" md="9" sm="9" xs="12">
      <SeguimientoForm class="mt-2 mx-4" />
      <Anammesis class="mt-4 mx-4" />
      <ListadoDescansosMedicos class="mt-4 mx-4" />
      <ListadoEvidencias class="mt-0 mx-4" />
      <ListadoSeguimientos class="mt-4 mx-4" />
      <DecisionMedica class="mt-2 mx-4" />
<!--       <div class="d-flex mt-2 mx-4">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="guardarAtencion()">GUARDAR</v-btn>
      </div> -->
    </v-col>
  </v-row>
</template>
<script>

/* import ContactosEmergencia from "@/components/ContactosEmergencia.vue"; */
import DatosTrabajador from "@/components/DatosTrabajador.vue";
import Comorbilidades from "@/components/Comorbilidades.vue";
import SeguimientosTimeline from "@/components/SeguimientosTimeline.vue";
import ExamenesOcupacionalesTimeline from "@/components/ExamenesOcupacionalesTimeline.vue";
import SeguimientoForm from "@/components/DetalleSeguimientoForm.vue";
import Anammesis from "@/components/Anammesis.vue";
import ListadoEvidencias from "@/components/ListadoEvidencias.vue";
import DecisionMedica from "@/components/DecisionMedica.vue";
import ListadoDescansosMedicos from "@/components/ListadoDescansosMedicos.vue";
import ListadoSeguimientos from "@/components/ListadoSeguimientos.vue";
import moment from "moment";

export default {
  name: 'DetalleSeguimiento',
  components: {
    ListadoSeguimientos,
    ListadoDescansosMedicos,
    DecisionMedica,
    ListadoEvidencias,
    Anammesis,
    SeguimientoForm,
    SeguimientosTimeline,
    Comorbilidades,
    DatosTrabajador,
   /*  ContactosEmergencia, */
    ExamenesOcupacionalesTimeline
  },
  data() {
    return {
      seguimiento: null,
      atencion: null,
      dialog: {
        descansoMedico: false,
      },
      moment,
    }
  },
  methods: {
    async getData(idSeguimiento) {
      this.$store.commit('SET_LOADING', true)
      try {
        this.seguimiento = await this.$store.dispatch('fetchSeguimiento', idSeguimiento)
        this.$store.commit('SET_LOADING', false)
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
        this.$store.commit('SET_LOADING', false)
      }
    },
    async guardarAtencion() {
      const formsValidArray = [
        await this.$refs.seguimiento.isValid(),
        await this.$refs.anammesis.isValid(),
        await this.$refs.decisionMedica.isValid()
      ]
      const allFormsAreValid = formsValidArray.every(val => !!val)
      if (!allFormsAreValid) {
        console.log('some component form is invalid')
        return
      }
      console.log(this.payload)
      this.$store.commit('SET_LOADING', true)
      try {
        const res = await this.$store.dispatch('saveAtencion', this.payload)
        await this.$router.push({ name: 'home' })
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SHOW_SUCCESS_SNACKBAR', res.message)
      } catch (e) {
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    },
  },
  computed: {
    anammesisCopy() {
      if (!this.atencion?.anammesis?.length) return []
      return [...this.atencion.anammesis]
        .map(an => {
          return {
            id: an.id,
            descripcion: an.enfermedad.descripcion,
            cie10: an.cie10,
            principal: an.principal
          }
        })
    },
    payload() {
      return {
        id: this.atencion.id,
        paciente: { ...this.$refs.datosTrabajador.paciente },
        descansos_medicos: [...this.$refs.descansosMedicos.descansos],
        seguimiento: {
          ...{ ...this.$refs.seguimiento.seguimiento },
          decision_medica: this.$refs.decisionMedica.seguimiento.decision_medica,
          fecha_seguimiento: this.$refs.decisionMedica.seguimiento.fecha_seguimiento,
          comentarios: this.$refs.decisionMedica.seguimiento.comentarios,
          fecha_inicio_sintomas: this.$refs.anammesis.fechaIni,
          fecha_proximo_seguimiento: this.$refs.decisionMedica.seguimiento.fecha_proximo_seguimiento
        },
        anammesis: [...this.$refs.anammesis.anammesis].map(an => {
          return {
            id: an.id,
            cie10: an.cie10,
            principal: an.principal
          }
        }),
      }
    },
    descansosMedicosCopy() {
      if (!this.atencion?.descansos_medicos?.length) return []
      return [...this.atencion.descansos_medicos].map(dm => {
        return {
          ...dm,
          fecha_inicio: dm.fecha_inicio ? moment(dm.fecha_inicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
          fecha_fin: dm.fecha_fin ? moment(dm.fecha_fin, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
        }
      })
    },
    contactosEmergenciaCopy() {
      if (!this.atencion || !this.atencion.paciente?.contactos_emergencia) return []
      return [...this.atencion.paciente.contactos_emergencia];
    }
  },

  created() {
    this.$store.commit('SET_SEGUIMIENTO_SELECCIONADO', {})
    this.$store.commit('SET_SEGUIMIENTOS_SEGUIMIENTO_SELECCIONADO', {})
    this.getData(this.$route.params.id)
    this.fotos = [];
  },
}
</script>