<template>
  <v-row dense>
    <v-col cols="12" lg="4" md="4" sm="4" xs="12">
      <v-card variant="flat" class="mt-0">
        <v-card-title>
          NUEVO PACIENTE
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="dni" density="compact" variant="solo" label="Buscar por DNI"
            append-inner-icon="mdi-magnify" single-line hide-details @click:append-inner="buscar()"
            @keydown.enter="buscar()"></v-text-field>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row dense>
    <v-col cols="12" >
      <InformacionTrabajador class="mt-0 mx-4" />
    </v-col>
  </v-row>
</template>
<script>

import InformacionTrabajador from "@/components/InformacionTrabajador.vue";
export default {
  name: 'NuevoPaciente',
  components: {
    InformacionTrabajador,
  },
  data() {
    return {
      dni:null
    }
  },
  methods: {

    buscar(){
      this.$store.dispatch('searchPacienteByDni',this.dni)
    }
  },
  computed: {
  },
  created() {
    let paciente = {
      validacion_reniec: {
      }
    };
    this.$store.commit('SET_TRABAJADOR_SELECCIONADO', paciente)
  },
}
</script>