<template>
  <v-row dense>
    <v-col cols="12">
      <InformacionTrabajador class="mt-2 mx-4" />
    </v-col>
  </v-row>
</template>
<script>

import InformacionTrabajador from "@/components/InformacionTrabajador.vue";


import moment from "moment";

export default {
  name: 'DetallePaciente',
  components: {
    InformacionTrabajador,
  },
  data() {
    return {
      seguimiento: null,
      atencion: null,
      dialog: {
        descansoMedico: false,
      },
      moment,
    }
  },
  methods: {
    async getData(idPaciente) {
      this.$store.commit('SET_LOADING', true)
      try {
        this.seguimiento = await this.$store.dispatch('fetchPaciente', idPaciente)
        this.$store.commit('SET_LOADING', false)
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
        this.$store.commit('SET_LOADING', false)
      }
    },
    guardarDatosLogin() {
      //  console.log(this.$store.state.trabajador_seleccionado)
      this.$store.dispatch('guardarDatosLogin', this.$store.state.trabajador_seleccionado)
      this.$router.push({ path: '/pacientes' })
    }
  },
  computed: {
  },

  created() {
    this.$store.commit('SET_SEGUIMIENTO_SELECCIONADO', {})
    this.$store.commit('SET_SEGUIMIENTOS_SEGUIMIENTO_SELECCIONADO', {})
    this.getData(this.$route.params.id)
    this.fotos = [];
  },
}
</script>