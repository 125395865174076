<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>PROGRAMACIONES</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="programaciones"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.posta`]="{ item }">
          POSTA {{ item.posta.descripcion }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ moment(item.fecha).format('DD-MM-YYYY') }} {{ item.hora }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon :color="item.status === 'PROGRAMADO' ? 'success' : 'red'">
            {{ item.status === 'PROGRAMADO' ? 'mdi-check' : 'mdi-close' }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "MedicacionActual",
  data() {
    return {
      moment,
      headers: [
        { title: 'Posta', value: 'posta' },
        { title: 'Fecha', value: 'fecha' },
        { title: 'Usuario', value: 'user.nombres' },
      ],
    };
  },
  computed: {
    programaciones() {
      return this.$store.state.formato_visitante_seleccionado.programacion; // Suponiendo que ahora es un array de programaciones
    },
  },
};
</script>
