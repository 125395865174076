<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>LISTADO DE MEDICAMENTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-data-table :headers="headers" :items="medicamentos" hide-default-footer density="compact" class="elevation-0">
        <template v-slot:[`item.acciones`]="{ item }">
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:bottom>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: 'ListadoMedicamentos',
  components: {
  },
  data() {
    return {
      headers: [
        { title: 'Descripcion', align: 'start', key: 'descripcion', sortable: false, },
        { title: 'Acciones', align: 'end', key: 'acciones', sortable: false, }
        /*   { text: 'Acciones', value: 'acciones' }, */
      ],
      moment
    }
  },
  methods: {

  },
  computed: {
    medicamentos: {
      get() {
        return this.$store.state.declaracion_medicamento_seleccionada.medicamento
      },
      set() {

      }
    }
  }
}
</script>