<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>DATOS DEL VISITANTE</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text v-if="visitante">
      <v-text-field v-model="nombres" density="compact" hide-details label="NOMBRE COMPLETO"></v-text-field>
      <v-text-field v-model="visitante.visitante.documento_identidad" hide-details density="compact"
        label="NUMERO DE DOCUMENTO"></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="fecha_nacimiento" hide-details density="compact" class="mr-2"
          label="FECHA DE NACIMIENTO"></v-text-field>
        <v-text-field v-model="visitante.visitante.edad" hide-details density="compact" label="EDAD"></v-text-field>
      </div>
      <v-text-field v-if="visitante.visitante.empresa_visitante" v-model="visitante.visitante.empresa_visitante.descripcion" hide-details density="compact" label="EMPRESA"></v-text-field>
      <v-text-field v-model="visitante.visitante.correo" hide-details density="compact" label="CORREO"></v-text-field>
      <v-text-field v-model="visitante.visitante.actividad_a_realizar" hide-details density="compact"
        label="ACTIVIDAD A REALIZAR"></v-text-field>
      <v-text-field v-model="visitante.visitante.contacto_emergencia" hide-details density="compact"
        label="CONTACTO DE EMERGENCIAA"></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="visitante.visitante.codigo_pais" hide-details density="compact"
          label="CODIGO PAIS"></v-text-field>
        <v-text-field v-model="visitante.visitante.telefono_contacto" hide-details density="compact"
          label="NUMERO DE CONTACTO"></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from 'moment'
export default {
  name: 'DatosVisitante',
  computed: {
    nombres() {
      return this.$store.state.formato_visitante_seleccionado.visitante.apellidos_nombres.toUpperCase()
    },
    empresa() {
      return this.$store.state.formato_visitante_seleccionado.visitante.empresa.toUpperCase()
    },
    visitante: {
      get() {
        return this.$store.state.formato_visitante_seleccionado
      },
      set() {
      }
    },
    fecha_nacimiento() {
      return moment(this.$store.state.formato_visitante_seleccionado.visitante.fecha_nacimiento).format('DD-MM-YYYY ')
    }
  }
}
</script>