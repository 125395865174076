<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar color="indigo-darken-4">
        <v-spacer></v-spacer>
        <h5>DESICIÓN MEDICA</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select v-model="desicion_medica" :items="decisionesMedicas" item-value="id" item-title="text"
              label="DESICION MEDICA"></v-select>
          </v-col>
          <v-col>
            <v-textarea v-model="comentarios" label="OBSERVACIONES"></v-textarea>
          </v-col>
          <v-col>
            <v-date-picker v-if="desicion_medica == 2" v-model="date" class="mx-4" density="compact" locale="es"
              title="FECHA PROXIMO SEGUIMIENTO"></v-date-picker>
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: 'DecisionMedica',
  data() {
    return {
      menu: false,
      seguimiento: this.data,
      desicion_medica: null,
      fecha_proximo_seguimiento: null,
      comentarios: null,
      date: '2023-10-18'
    }
  },
  computed: {
    ...mapGetters(['decisionesMedicas'])
  },
  methods: {
    async isValid() {
      return await this.$refs.decisionMedicaForm.validate();
    }
  }
}

</script>