<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>FORMULARIO DE SEGUIMIENTO</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-text-field label="COMUNICACION SEGUIMIENTO"></v-text-field>
      <v-textarea label="INFORMACION SUMINISTRADA"></v-textarea>
      <div class="d-flex">
        <v-select class="mr-4" label="MOTIVO DE SEGUIMIENTO" :items="motivosSeguimiento" item-title="text"
          item-value="id"></v-select>
        <v-text-field label="MOTIVO DE SEGUIMIENTO"></v-text-field>
      </div>
      <v-radio-group class="mt-auto" row>
        <v-radio v-for="e in estadosSeguimiento" :key="e.id" :label="e.text" :value="e.id"></v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'DetalleSeguimientoForm',
  data() {
    return {
      seguimiento: null,
    }
  },
  computed: {
    ...mapGetters(['estadosSeguimiento']),
    motivosSeguimiento() {
      return [
        { id: 1, text: 'SINTOMAS RESPIRATORIOS' },
        { id: 2, text: 'OTROS' }
      ]
    }
  },
  methods: {
    async isValid() {
      return await this.$refs.detalleSeguimientoForm.validate();
    }
  }
}

</script>