<!-- ConfirmDialog.vue -->
<template>
    <v-dialog :model-value="isVisible" @update:model-value="updateVisibility" max-width="400px">
      <v-card class="mt-0">
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirm">Sí</v-btn>
          <v-btn color="red darken-1" text @click="cancel">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'ConfirmDialog',
    props: {
      title: {
        type: String,
        default: 'Confirmación',
      },
      message: {
        type: String,
        default: '¿Está seguro de que desea realizar esta acción?',
      },
      isVisible: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['update:isVisible', 'confirm', 'cancel'],
    methods: {
      confirm() {
        this.$emit('confirm');
        this.updateVisibility(false); // Cierra el diálogo al confirmar
      },
      cancel() {
        this.$emit('cancel');
        this.updateVisibility(false); // Cierra el diálogo al cancelar
      },
      updateVisibility(value) {
        this.$emit('update:isVisible', value);
      }
    },
  };
  </script>
  