<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>CALIFICACIÓN</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6" align-self="end">
          <v-img class="mx-auto  bg-grey-lighten-2" height="125" width="100" contain :src="foto"></v-img>
        </v-col>
        <v-col cols="6" class="text-center">
          <div class="text-overline">{{ $store.state.user.cmp }}</div>
          <div class="text-overline">{{ $store.state.user.nombres }}</div>
          <div class="text-overline">{{ $store.state.user.apellido_paterno }} {{ $store.state.user.apellido_materno }}
          </div>
          <div class="text-overline text-green">HABILITADO</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mt-0" :disabled="$store.state.formato_visitante_seleccionado.programacion && $store.state.formato_visitante_seleccionado.programacion.length > 0">
            <v-card-title>
              Habilitación Visita
            </v-card-title>
            <v-card-text>
              <v-select hide-details v-model="habilitacion.fv_habilitacion_id" label="HABILITACION"
                item-title="descripcion" item-value="id" density="compact"
                :items="this.$store.state.habilitacionesCalificacionFormatoVisitante"></v-select>
              <v-textarea v-model="habilitacion.observaciones" label="OBSERVACIONES" rows="4"
                density="compact"></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mt-0" :disabled="!($store.state.formato_visitante_seleccionado.programacion && $store.state.formato_visitante_seleccionado.programacion.length > 0)">
            <v-card-title>
              Calificación Presencial 
            </v-card-title>
            <v-card-text>
              <v-select hide-details v-model="calificacion.aptitud_calificacion_formato_visita_id" label="CALIFICACIÓN"
                item-title="descripcion" item-value="id" density="compact"
                :items="this.$store.state.aptitudesCalificacionFormatoVisitante"></v-select>
              <v-textarea v-model="calificacion.observaciones" label="OBSERVACIONES" rows="4"
                density="compact"></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    habilitacion() {
      const habilitacion = this.$store.state.formato_visitante_seleccionado.habilitacion;
      return habilitacion ? habilitacion : { fv_habilitacion_id: null, observaciones: '', user_id: this.$store.state.user.id };
    },
    calificacion() {
      const calificacion = this.$store.state.formato_visitante_seleccionado.calificacion;
      return calificacion ? calificacion : { aptitud_calificacion_formato_visita_id: null, observaciones: '', user_id: this.$store.state.user.id };
    },
    foto() {
      return `https://200.48.13.39/conoce_a_tu_medico/fotos/${this.$store.state.user.cmp}.jpg`;
    }
  },
  watch: {
    'calificacion.aptitud_calificacion_formato_visita_id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_CALIFICACION', { ...this.calificacion, aptitud_calificacion_formato_visita_id: newValue });
        }
      },
      deep: true
    },
    'calificacion.observaciones': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_CALIFICACION', { ...this.calificacion, observaciones: newValue });
        }
      },
      deep: true
    },
    'habilitacion.fv_habilitacion_id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_HABILITACION', { ...this.habilitacion, fv_habilitacion_id: newValue });
        }
      },
      deep: true
    },
    'habilitacion.observaciones': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.commit('SET_HABILITACION', { ...this.habilitacion, observaciones: newValue });
        }
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch('fetchAptitudCalificacionFormatoVisitante');
    this.$store.dispatch('fetchFvHabilitacionFormatoVisitante');
  }
}
</script>
