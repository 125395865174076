<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar dense color="indigo-darken-4" dark>
        <v-spacer></v-spacer>
        <h5>EVIDENCIAS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-data-table v-if="evidencias && evidencias.length > 0" :headers="headers" :items="evidencias"
          hide-default-footer density="compact" class="elevation-0">
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon @click="seleccionarEvidencia(item)" icon="mdi-eye"></v-icon>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD-MM-YYYY hh:mm A') }}
          </template>
          <template v-slot:bottom>
          </template>
        </v-data-table>
        <template v-else>
          <p>Sin data para mostrar</p>
        </template>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: 'ListadoEvidencias',
  components: {
  },
  data() {
    return {
      moment,
      headers: [
        { title: 'Fecha', align: 'start', key: 'created_at', sortable: false, },
        { title: 'Tipo de Archivo', align: 'start', key: 'tipo_archivo', sortable: false, },
        { title: 'Acciones', align: 'end', key: 'acciones', sortable: false, }
      ],
    }
  },
  methods: {
    seleccionarEvidencia(item) {
      //console.log(item)
      this.$store.commit('SET_EVIDENCIA_MEDICAMENTO_SELECCIONADA', item)
      this.fotos = [];
      this.fotos.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + item.ruta);
    }
  },
  computed: {
    evidencias: {
      get() {
        return this.$store.state.declaracion_medicamento_seleccionada.evidencias
      },
      set() {
      }
    },
    fotos: {
      get() {
        return this.$store.state.fotos_evidencia_medicamento
      },
      set(val) {
        this.$store.commit('SET_FOTOS_EVIDENCIA_MEDICAMENTO', val)
      }
    },
  }
}
</script>