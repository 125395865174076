<template>
  <v-row class="mt-0">
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <DatosTrabajador class="mt-0 mx-4" />
      <!--       <ContactosEmergencia class="mt-2 mx-4" /> -->
      <!--  <SeguimientosTimeline :data="[...atencion.seguimientos]" class="mt-4" /> -->
    </v-col>
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <ListadoMedicamentos class="mt-0" />

      <ListadoEvidenciasDeclaracionMedicamento class="mt-4" />
      <ComentariosDeclaracionMedicamento class="mt-4" />
      <DecisionMedicaDeclaracionMedicamento class="mt-2" />
      <!--       <ListadoAnotacionesDeclaracionMedicamento class="mt-4" /> -->
      <!--  <DecisionMedicaDeclaracionMedicamento class="mt-4" /> -->
      <div class="d-flex mt-2">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="guardarAtencion()">GUARDAR</v-btn>
      </div>
    </v-col>
    <v-col cols="12" lg="6" md="12" sm="12" xs="12">
      <VisorEvidenciasDeclaracionMedicamento class="mt-0" />
      <!--   <v-col cols="12" lg="5" md="9" sm="9" xs="12">
        sasf
      </v-col> -->
      <!--  <SeguimientoForm
          class="mt-0"
          ref="seguimiento"
          :data="{...seguimiento}"
      /> 
      <Anammesis
          class="mt-4"
          ref="anammesis"
          :anammesisArr="anammesisCopy"
          :fecha_inicio_sintomas="seguimiento.fecha_inicio_sintomas"
      />
      <ListadoDescansosMedicos
          class="mt-4"
          :data="descansosMedicosCopy"
          ref="descansosMedicos"
      />
      <ListadoEvidencias
          :evidencias="[...atencion.evidencias]"
          class="mt-0"
      /> -->


    </v-col>
  </v-row>
</template>
<script>
/* import ContactosEmergencia from "@/components/ContactosEmergencia.vue"; */
import DatosTrabajador from "@/components/DatosTrabajador.vue";
import ListadoEvidenciasDeclaracionMedicamento from "@/components/ListadoEvidenciasDeclaracionMedicamento.vue";
import ListadoMedicamentos from "@/components/ListadoMedicamentos.vue"
/* import ListadoAnotacionesDeclaracionMedicamento from "@/components/ListadoAnotacionesDeclaracionMedicamento.vue" */
import DecisionMedicaDeclaracionMedicamento from "@/components/DecisionMedicaDeclaracionMedicamento.vue"
import VisorEvidenciasDeclaracionMedicamento from "@/components/VisorEvidenciasDeclaracionMedicamento.vue";
import ComentariosDeclaracionMedicamento from "@/components/ComentariosDeclaracionMedicamento.vue";
import moment from "moment";

export default {
  name: 'DetalleSeguimiento',
  components: {
    DatosTrabajador,
    /*     ContactosEmergencia, */
    ListadoEvidenciasDeclaracionMedicamento,
    ListadoMedicamentos,
    /*     ListadoAnotacionesDeclaracionMedicamento, */
    DecisionMedicaDeclaracionMedicamento,
    VisorEvidenciasDeclaracionMedicamento,
    ComentariosDeclaracionMedicamento
  },
  data() {
    return {
      moment,

    }
  },
  methods: {
    async getData(idSeguimiento) {
      this.$store.commit('SET_LOADING', true)
      try {
        this.$store.dispatch('fetchDeclaracion', idSeguimiento)
        // this.atencion = await this.$store.dispatch('fetchAtencion', this.seguimiento.atencion_descanso_id)
        this.$store.commit('SET_LOADING', false)
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
        this.$store.commit('SET_LOADING', false)
      }
    },
    async guardarAtencion() {
      /*   const formsValidArray = [
          await this.$refs.seguimiento.isValid(),
          await this.$refs.anammesis.isValid(),
          await this.$refs.decisionMedica.isValid()
        ]
        const allFormsAreValid = formsValidArray.every(val => !!val)
        if (!allFormsAreValid) {
          console.log('some component form is invalid')
          return
        }
        console.log(this.payload)
        this.$store.commit('SET_LOADING', true)
         */
      try {
        await this.$store.dispatch('saveDeclaracionMedicamento', this.$route.params.id)
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SHOW_SUCCESS_SNACKBAR', 'Declaracion guardad Correctamente')
        this.$router.push({ path: '/medicamentos' })
      } catch (e) {
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }

    },
  },
  computed: {
    fotos: {
      get() {
        return this.$store.state.fotos_evidencia_medicamento
      },
      set(val) {
        this.$store.commit('SET_FOTOS_EVIDENCIA_MEDICAMENTO', val)
      }
    },
  },
  created() {
    this.getData(this.$route.params.id)
    this.$store.commit('SET_EVIDENCIA_MEDICAMENTO_SELECCIONADA', {})
    this.fotos = [];
  },

}
</script>